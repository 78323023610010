import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import { constantMethod } from "../../utils/constantMethod";
import { useAppSelector } from "../../app/hooks";
import { roomSelector } from "../../features/room/roomSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import {
  resolutionConstant,
  roomViewModeConst,
  socketConnectiontype,
} from "../../utils/constant";
import SocketService from "../../services/socket";
import { SourcePlayback } from "../../helpers/sourceHelper";

const mediaSocketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

interface VideoProps {
  className?: string;
  src?: any;
  reference?: any;
  style?: any;
  mediaType?: string;
  userId?: string;
  onLoad?: (sourcePlayback: SourcePlayback) => void;
}
export default function Video(props: VideoProps): ReturnType<React.FC> {
  const [self, setSelf] = useState<HTMLVideoElement | null>(null);
  const { viewMode } = useAppSelector(roomSelector);
  const { username, role } = useAppSelector(userDetailsSelector);
  const { pathname } = useLocation();
  console.log(pathname.includes("/room"));
  const openWindows = useRef([] as any);

  const handleDoubleClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    // if (!pathname.includes("/room")) return;

    // if (props.screenControl) return;
    console.log({event})
    const videoElement = event.target as HTMLVideoElement;
    const mediaType = videoElement.dataset.mediatype;
    const id = videoElement.id;

    console.log({videoElement,mediaType})
    if (
      constantMethod.checkHigherRole(role) &&
      viewMode === roomViewModeConst.lectureMode &&
      props.mediaType &&
      id
    ) {
      mediaSocketInstance.sendMessage(
        "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
        {
          peerUserId: id,
          resolution: resolutionConstant.high,
          mediaType: mediaType,
          username,
        }
      );
    }

    const myWindow = window.open(
      "",
      "MsgWindow" + id + new Date(),
      "width=1000,height=1000"
    );

    myWindow?.addEventListener("unload", function (e) {
      if (
        constantMethod.checkHigherRole(role) &&
        viewMode === roomViewModeConst.lectureMode &&
        props.mediaType &&
        id
      ) {
        mediaSocketInstance.sendMessage(
          "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
          {
            peerUserId: id,
            resolution: resolutionConstant.low,
            mediaType: mediaType,
            username,
          }
        );
      }
    });
    // https://archive.org/download/Popeye_forPresident/Popeye_forPresident_512kb.mp4

    const wrapper = document.createElement("div");
    if (myWindow) {
      myWindow.document.body.appendChild(wrapper);
      myWindow.document.title = "Test";
    }

    var style = myWindow?.document.createElement("style");
    if (style) {
      style.innerText = `.videoInsert {
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }`;
      myWindow?.document.head.appendChild(style);
    }
    const videlem = document.createElement("video");
    videlem.className = "videoInsert";
    videlem.srcObject = videoElement.srcObject;
    videlem.height = 600;
    videlem.width = 800;
    videlem.autoplay = true;
    videlem.muted = true;
    videlem.playsInline = true;
    wrapper.appendChild(videlem);

    openWindows.current.push(myWindow);
  };

  const handleVideoLoad = (event: SyntheticEvent) => {
    const video = event.target as HTMLVideoElement;
    if (props.onLoad) {
      props.onLoad({
        htmlElement: video,
        width: video.videoWidth,
        height: video.videoHeight,
      });
    }
  };

  useEffect(() => {
    if (props.reference) {
      // console.log("External SRC OBJECT")
      props.reference.current.srcObject = props.src;
    } else {
      if (self) {
        if (props.src) {
          // console.log("Internal SRC OBJECT")
          self.srcObject = props.src;
        }
      }
    }
  }, [props.reference, props.src, self]);

  useEffect(() => {
    let openwindoscurrent = openWindows.current;
    return () => {
      for (let index = 0; index < openwindoscurrent.length; index++) {
        const myWindow = openwindoscurrent[index];
        myWindow?.close();
      }
    };
  }, []);

  return (
    <>
      <video
        className={`rounded-xl h-full w-full ` + props.className}
        ref={props.reference ? props.reference : setSelf}
        style={props.style}
        data-mediatype={props.mediaType}
        autoPlay
        loop
        playsInline
        muted
        id={props.userId}
        onDoubleClick={handleDoubleClick}
        onLoadedData={handleVideoLoad}
        // onMouseOver={getVIdeoDimensions}
        // onMouseLeave = {clearDimensionVar}
      >
        Your browser does not support the video tag.
      </video>
    </>
  );
}