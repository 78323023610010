import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/ErrorBoundary';


// Unregister stale service workers to prevent cache issues
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

// Global error listener to catch CSS and JS chunk load errors
window.addEventListener("error", (e: any) => {
  const target = e.target as HTMLLinkElement | HTMLScriptElement ;

  // Check if CSS or JS chunk load error occurred
  if (
    // @ts-ignore 
    (target.tagName === "LINK" && target.href.includes("/static/css/")) ||
    // @ts-ignore 
    (target.tagName === "SCRIPT" && target.src.includes("/static/js/"))
  ) {
    console.error("Chunk load failed. Reloading...");
    window.location.reload(); // Reload to fetch latest chunks
  }
});

// Handle unhandled promise rejections for chunk load errors
window.addEventListener("unhandledrejection", (e: PromiseRejectionEvent) => {
  if (e.reason && e.reason.name === "ChunkLoadError") {
    console.error("Chunk load failed (Promise rejection). Reloading...");
    window.location.reload();
  }
});


const retryFailedCSS = () => {
  const links = document.querySelectorAll('link[rel="stylesheet"]');
  links.forEach((link) => {
    // @ts-ignore 
    if (link.href.includes("/static/css/")) {
      const newLink = link.cloneNode() as HTMLLinkElement;
      newLink.onload = () => {
        console.log("CSS Chunk reloaded successfully.");
      };
      newLink.onerror = () => {
        console.error("CSS Chunk retry failed. Reloading...");
        window.location.reload();
      };
      link.parentNode?.replaceChild(newLink, link);
    }
  });
};


setTimeout(retryFailedCSS, 2000);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ErrorBoundary>
    <Provider store={store}>
    <App />
    </Provider>
    </ErrorBoundary>
  // </React.StrictMode>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
