import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IConnectionDetails } from "../../interfaces/componentsInterface/whiteboard";
import { getConnectionDetailsForDesktopApp } from "../../services/connectionDetails";
import { useLocation } from "react-router-dom";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { useAppSelector } from "../../app/hooks";
import { callCustomProtocol, generateCustomProtocolURL } from "../../utils/generateCustomProtocolURL";

const LaunchApp = () => {
  const { roomId, roomname } = useParams();

  const useQuery = () => new URLSearchParams(useLocation().search);

  const custCode = useAppSelector(userDetailsSelector).custCode;
  let query = useQuery();
  const donwloadLink:any = query.get('download');

  const openElectronApp = async () => {
    if (roomId && roomname) {


      const connectionDetails: IConnectionDetails =
        await getConnectionDetailsForDesktopApp(roomname, roomId);

        let hostname = process.env.REACT_APP_DESK_WS_URL!;

        if (!hostname) {
          if (window.location.origin.includes("localhost")) {
            hostname = "http://localhost:8085";
          } else {
            hostname = window.location.origin; // Default to the current origin
          }
        }

      if(connectionDetails) {
        const customProtolURL = generateCustomProtocolURL(
              hostname, 
              connectionDetails.path, 
              connectionDetails.iv, 
              connectionDetails.digest, 
              connectionDetails.payload, 
              custCode)
      
        callCustomProtocol(customProtolURL);
      }
    }
  };

  const downloadScreenControlApp = () => {
    window.location.href = donwloadLink
    setTimeout(() => window.close(), 1000);
  };

  useEffect(() => {
    openElectronApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className=" w-full h-screen flex flex-col justify-center items-center">
        <div className="mb-[20px]">
          <h1>
            Welcome to{" "}
            <b style={{ textDecoration: "underline" }}>CollaborationRoom</b>
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center text-sm">
          <h4>Click On Download To Download app</h4>
          <h6>If you have alreday installed Please Click on close button</h6>
        </div>
        <div className=" flex w-1/3 justify-around mt-[20px] gap-3">
          <button
            className=" w-full  bg-blue rounded-xl text-white p-1"
            onClick={downloadScreenControlApp}
            title="Download App"
          >
            Download
          </button>

          <button
            className="w-full bg-blue rounded-xl text-white p-1"
            onClick={() => openElectronApp()}
            title="Open app"
          >
            Launch App
          </button>
        </div>
      </div>
    </>
  );
};

export default LaunchApp;
